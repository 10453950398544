// 
let lastScrollTop = 0;
if ($( window ).width() > 992) {
    $(window).scroll(function(event){

        let st = $(this).scrollTop();
            if (st > lastScrollTop){
                $('header').css('position', 'fixed').css('top', '0');
            } else {
                // upscroll code
            }
            lastScrollTop = st;
    });
};