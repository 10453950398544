import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  // require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  // require("./pop-up");


  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });

  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  $('.project__slider').slick({
    dots: false,
    infinite: true,
    slidesToShow: 1,
    prevArrow: '<button class="project__slider-arrow project__slider-arrow_prev"></button>',
    nextArrow: '<button class="project__slider-arrow project__slider-arrow_next"></button>',
    speed: 500,
    fade: true,
    cssEase: 'linear',
  });

  $('.top__slider').slick({
    prevArrow: false,
    nextArrow: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    speed: 1000,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 5000,
  });

  
});
